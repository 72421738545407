<!--
 * @Descripttion : 密钥管理
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-09 17:37:25
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-14 10:46:45
-->
<template>
  <div>
    <div style="padding:10px 0;">
      <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
        <a-form-model-item>
          <a-input-search v-model="formInline.keyWord" placeholder="AppName/AppId/appSecret" allowClear enter-button @search="getList" />
        </a-form-model-item>
        <a-form-model-item v-if="permissions.indexOf('appSecret:add')>-1">
          <a-button type="primary" icon="plus" @click="addAppSecret" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table :columns="columns" bordered :data-source="data" :rowKey='record=>record.id' :pagination="pagination" :loading="loading" @change="handleTableChange" size="middle">

      <template slot="isDisable" slot-scope="record">
        {{record.isDisable==1?'是':'否'}}

      </template>
      <template slot="operation" slot-scope="record">
        <a-space>
          <a-button type="primary" size="small" v-if="permissions.indexOf('appSecret:edit')>-1" icon="edit" @click="editAppSecret(record)" />
          <a-button type="danger" size="small" v-if="permissions.indexOf('appSecret:delete')>-1" icon="delete" @click="delAppSecret(record)" />
        </a-space>
      </template>
    </a-table>

    <!-- 新增/编辑 -->
    <a-drawer :title="title" :width="350" :visible="visibleAddEdit" @close="onClose">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="对接方的appName" prop="appName">
          <a-input v-model="form.appName" placeholder="请输入对接方的appName" />
        </a-form-model-item>
        <a-form-model-item label="对接方回调url" prop="callBackUrl">
          <a-input v-model="form.callBackUrl" placeholder="请输入对接方回调url" />
        </a-form-model-item>
        <a-form-model-item label="集团ID" prop="groupId">
          <a-input v-model="form.groupId" placeholder="请输入集团ID" />
        </a-form-model-item>
        <a-form-model-item label="是否禁用" prop="formDisable">
          <a-switch v-model="form.formDisable" checked-children="是" un-checked-children="否" />
        </a-form-model-item>
      </a-form-model>
      <div class="ml_drawer_footer">
        <a-button @click="onClose">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="onSubmit">
          确定
        </a-button>
      </div>
    </a-drawer>

  </div>
</template>
<script>
import {
  getAppSecret,
  delAppSecret,
  addAppSecret,
  updateAppSecret,
  getAppSecretList
} from "@/api/system";

import { mapState } from "vuex";

const columns = [
  { title: "主键ID", dataIndex: "id", align: "center" },
  { title: "对接方appIdID", dataIndex: "appId", align: "center" },
  { title: "对接方名称", dataIndex: "appName", align: "center" },
  { title: "对接方的appSecret", dataIndex: "appSecret", align: "center" },
  { title: "对接方回调url", dataIndex: "callBackUrl", align: "center" },
  { title: "集团ID", dataIndex: "groupId", align: "center" },
  {
    title: "是否禁用",
    scopedSlots: { customRender: "isDisable" },
    align: "center"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
    align: "center"
  }
];

export default {
  data() {
    return {
      columns,
      data: [],
      loading: false,
      pagination: {
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40"],
        showSizeChanger: true,
        current: 1,
        total: 0
      },
      visibleAddEdit: false, //  是否显示操作
      formInline: {
        keyWord: ""
      },
      title: "",
      form: {
        appName: "",
        callBackUrl: "",
        groupId: "",
        id: "",
        isDisable: 0,
        formDisable: false
      },
      rules: {
        appName: [{ required: true, message: "请输入对接方的appName" }],
        callBackUrl: [{ required: true, message: "请输入对接方回调url" }],
        groupId: [{ required: true, message: "请输入集团ID" }]
      }
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.userStore.permissions
    })
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      //   获取用户列表
      const that = this;
      that.loading = true;
      getAppSecretList({
        keyWord: that.formInline.keyWord,
        pageNum: that.pagination.current,
        pageSize: that.pagination.pageSize,
        _: new Date().getTime()
      })
        .then(res => {
          that.loading = false;
          that.data = res.data.list;
          that.pagination.total = res.data.total;
        })
        .catch(err => {
          console.log(err);
          that.loading = false;
        });
    },
    onClose() {
      const that = this;
      that.$refs.ruleForm.resetFields();
      that.visibleAddEdit = false;
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      this.pagination = { ...pagination };
      this.getList();
    },
    delAppSecret(e) {
      const that = this;
      that.$confirm({
        title: "是否确认删除该密钥?",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          delAppSecret(e.id).then(() => {
            that.$message.success({
              content: "删除成功！",
              duration: 1,
              onClose: () => {
                that.getList();
              }
            });
          });
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },
    editAppSecret(e) {
      const that = this;
      that.visibleAddEdit = true;
      that.form.id = e.id;
      that.title = "编辑密钥";

      getAppSecret(e.id).then(res => {
        if (res.data.isDisable === 1) {
          res.data.formDisable = true;
        } else {
          res.data.formDisable = false;
        }
        delete res.data.updateTime;
        that.form = { ...res.data };
      });
    },
    addAppSecret() {
      const that = this;
      that.visibleAddEdit = true;
      that.form.id = "";
      that.title = "新增密钥";
      that.$nextTick(() => {
        that.$refs.ruleForm.resetFields();
      });
    },
    onSubmit() {
      const that = this;

      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          that.form["json"] = true;
          if (that.form.formDisable) {
            that.form.isDisable = 1;
          } else {
            that.form.isDisable = 0;
          }
          if (that.form.id) {
            updateAppSecret(that.form).then(() => {
              that.$message.success({
                content: "修改成功！",
                duration: 1,
                onClose: () => {
                  that.visibleAddEdit = false;
                  that.getList();
                }
              });
            });
          } else {
            addAppSecret(that.form).then(() => {
              that.$message.success({
                content: "添加成功！",
                duration: 1,
                onClose: () => {
                  that.visibleAddEdit = false;
                  that.getList();
                }
              });
            });
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
</style>